var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "number_area" }, [
    _c("ul", { staticClass: "number_list" }, [
      _c("li", [_vm._v("証券番号 " + _vm._s(_vm.contract.policyNumber))]),
      _c("li", [_vm._v("案件番号 " + _vm._s(_vm.contract.number))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }